import NavItem from './NavItem';
import MenuLinks from './config';
import PropTypes from 'prop-types';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import React, { useEffect } from 'react';
import UserAvatar from 'src/components/UserAvatar';
import Scrollbars from 'src/components/Scrollbars';
import { PATH_APP } from 'src/routes/paths';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  Drawer,
  Hidden,
  Typography,
  ListSubheader,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    drawer: {
      [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      background: theme.palette.background.default,
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    account: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 2.5),
      margin: theme.spacing(1, 2.5, 5),
      borderRadius: theme.shape.borderRadiusSm,
      background: theme.palette.grey[isLight ? 200 : 800],
    },
    doc: {
      padding: theme.spacing(2.5),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.primary.lighter,
    },
  };
});

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level, can, t }) {
  const key = item.href + level;
  const otherProps = {};
  const title = t(`navBar.${item.title}`);

  const ca = item.canAccess;
  if (ca != null) {
    let allowed = false;
    for (const check of Array.isArray(ca[0]) ? ca : [ca]) {
      allowed = can(...check);
      if (allowed) {
        break;
      }
    }
    if (!allowed) {
      return array;
    }
  }

  if (item.items) {
    otherProps.open = Boolean(
      matchPath(pathname, {
        path: item.href,
        exact: false,
      }),
    );
    otherProps.children = renderNavItems({
      items: item.items,
      pathname,
      can,
      t,
      level: level + 1,
    });
  }
  return [
    ...array,
    <NavItem
      key={key}
      level={level}
      icon={item.icon}
      info={item.info}
      href={item.href}
      title={title}
      {...otherProps}
    />,
  ];
}

function renderNavItems({ items, pathname, can, t, level = 0 }) {
  const children = items.reduce(
    (array, item) => reduceChild({ array, item, pathname, can, t, level }),
    [],
  );
  if (children.length === 0) {
    return null;
  } else {
    return <List disablePadding>{children}</List>;
  }
}

MenuSection.propTypes = {
  classes: PropTypes.object,
  list: PropTypes.shape({
    subheader: PropTypes.string,
    items: PropTypes.array,
  }),
  pathname: PropTypes.string,
  t: PropTypes.func,
};

function MenuSection({ classes, list: { subheader, items }, pathname, t }) {
  const { can } = useAuth();
  const navItems = renderNavItems({ items, pathname, can, t });
  if (navItems == null) {
    return null;
  }
  return (
    <List
      disablePadding
      subheader={
        <ListSubheader
          disableSticky
          disableGutters
          className={classes.subHeader}
        >
          {t(`navBar.${subheader}`)}
        </ListSubheader>
      }
    >
      {navItems}
    </List>
  );
}

NavBar.propTypes = {
  onCloseNav: PropTypes.func,
  isOpenNav: PropTypes.bool,
};

function NavBar({ isOpenNav, onCloseNav }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpenNav && onCloseNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbars>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>

      <Link underline="none" component={RouterLink} to={PATH_APP.account}>
        <div className={classes.account}>
          <UserAvatar user={user} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {user.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t(`vars.roles.${user.role}`)}
            </Typography>
          </Box>
        </div>
      </Link>

      {MenuLinks.map((list) => (
        <MenuSection key={list.subheader} {...{ classes, list, pathname, t }} />
      ))}
    </Scrollbars>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default NavBar;
