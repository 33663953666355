import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import type { ServiceRequestOnListDto } from 'clima-service-api/dist/service/dto/service-request-on-list.dto';
import type { DeviceOnListDto } from 'clima-service-api/dist/devices/dto/device/device-on-list.dto';
import type { ModelOnListDto } from 'clima-service-api/dist/devices/dto/model/model-on-list.dto';
import type { ClientOnListDto } from 'clima-service-api/dist/devices/dto/client/client-on-list.dto';
import type { ServiceOnListDto } from 'clima-service-api/dist/devices/dto/service/service-on-list.dto';
import type { UserOnListDto } from 'clima-service-api/dist/service/dto/user-on-list.dto';
import type { ServiceRequestForEditDto } from 'clima-service-api/dist/service/dto/service-request-for-edit.dto';
import type { UserNotificationsDto } from 'clima-service-api/dist/auth/dto/user-notifications.dto';
import type { ChangeUserPasswordDto } from 'clima-service-api/dist/auth/dto/change-user-password.dto';
import type { CreateUserDto } from 'clima-service-api/dist/auth/dto/create-user.dto';
import type { UpdateUserDto } from 'clima-service-api/dist/auth/dto/update-user.dto';
import type { DeactivateUsersDto } from 'clima-service-api/dist/auth/dto/deactivate-users.dto';
import type { DeviceForEditDto } from 'clima-service-api/dist/devices/dto/device/device-for-edit.dto';
import type { RemoveDevicesDto } from 'clima-service-api/dist/devices/dto/device/remove-devices.dto';
import type { FindAllResult } from 'clima-service-api/dist';
import type { FindAllParamsDto as FindAllRequestsParamsDto } from 'clima-service-api/dist/service/dto/find-all-params.dto';
import type { FindAllParamsDto as FindAllDevicesParamsDto } from 'clima-service-api/dist/devices/dto/device/find-all-params.dto';
import type { FileDto } from 'clima-service-api/dist/files/dto/file.dto';
import type { MessageList } from 'clima-service-api/dist/service/controller/chat.controller';
import type { ImportResultDto } from 'clima-service-api/dist/devices/dto/import.result.dto';

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'accessToken';

interface LoginValues {
  email: string;
  password: string;
}

const axiosApi = axios.create({
  headers: { 'Content-Type': 'application/json; charset=UTF-8' },
  baseURL: '/api/v1/',
});

const isValidToken = (accessToken: string) => {
  const currentTime = Date.now() / 1000;
  const payload = jwtDecode<JwtPayload>(accessToken);
  return payload?.exp != null && payload.exp > currentTime;
};

const loadApiAccessToken = () => {
  const token = window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  if (token != null && isValidToken(token)) {
    setApiAccessToken(token);
  } else {
    setApiAccessToken(undefined);
    throw new Error('Empty or invalid accessToken!');
  }
};

const getApiAccessTokenIssuedAt = () => {
  const token = window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  if (token == null) {
    return;
  }
  const payload = jwtDecode<JwtPayload>(token);
  return payload?.iat;
};

const setApiAccessToken = (token?: string) => {
  if (token != null) {
    axiosApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, token);
  } else {
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  }
};

const { post, get, put } = axiosApi;

const multipartConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const getServiceRequest = async (url: string) => {
  function fixDate<T>(date: T) {
    return typeof date === 'string' ? new Date(date) : date;
  }
  const response = await get<ServiceRequestForEditDto>(url);
  const { data: request } = response;
  request.createdAt = fixDate(request.createdAt);
  request.updatedAt = fixDate(request.updatedAt);
  request.history.forEach(
    (entry) => (entry.createdAt = fixDate(entry.createdAt)),
  );
  request.files.forEach((file) => (file.createdAt = fixDate(file.createdAt)));
  return response;
};

const api = {
  auth: {
    login: (values: LoginValues) => post('auth/login', values),
    refresh: () => put('auth/refresh'),
    getAccount: () => get('auth/account'),
    getNotifications: () => get<UserNotificationsDto>('auth/notifications'),
    updateNotifications: (values: UserNotificationsDto) =>
      put('auth/notifications', values),
    updatePassword: (values: ChangeUserPasswordDto) =>
      put('auth/password', values),
  },
  users: {
    findAll: () => get<UserOnListDto[]>('users'),
    findOne: (id: string) => get<UserOnListDto>(`users/${id}`),
    create: (values: CreateUserDto) => post('users', values),
    update: (id: string, values: UpdateUserDto) => put(`users/${id}`, values),
    deactivate: (values: DeactivateUsersDto) => put(`users/deactivate`, values),
  },
  devices: {
    findAll: (params: FindAllDevicesParamsDto) =>
      get<FindAllResult<DeviceOnListDto>>('devices', { params }),
    findOne: (id: string) => get<DeviceForEditDto>(`devices/${id}`),
    create: (formData: FormData) => post('devices', formData, multipartConfig),
    update: (id: string, formData: FormData) =>
      put(`devices/${id}`, formData, multipartConfig),
    remove: (values: RemoveDevicesDto) =>
      axiosApi.delete('devices', { data: values }),
    downloadFile: (id: string, fileId: string) =>
      get<Blob>(`devices/${id}/files/${fileId}`, { responseType: 'blob' }),
    checkUniqueNumbers: (numbers: number[]) =>
      put(`devices/check/unique-numbers`, numbers),
    checkNumbers: (numbers: string[]) => put(`devices/check/numbers`, numbers),
    getRepresentations: () => get<string[]>('devices-representations'),
  },
  serviceRequests: {
    findAll: (params: FindAllRequestsParamsDto) =>
      get<FindAllResult<ServiceRequestOnListDto>>('service-requests', {
        params,
      }),
    findOne: (id: string) => getServiceRequest(`service-requests/${id}`),
    create: (formData: FormData) =>
      post('service-requests', formData, multipartConfig),
    createExternal: (formData: FormData) =>
      post('external-service-requests', formData, multipartConfig),
    update: (id: string, formData: FormData) =>
      put(`service-requests/${id}`, formData, multipartConfig),
    downloadFile: (id: string, fileId: string) =>
      get<Blob>(`service-requests/${id}/files/${fileId}`, {
        responseType: 'blob',
      }),
  },
  protocols: {
    findAll: () => get<FileDto[]>('protocols'),
    downloadFile: (fileId: string) =>
      get<Blob>(`protocols/${fileId}`, {
        responseType: 'blob',
      }),
  },
  models: {
    findAll: () => get<ModelOnListDto[]>('models'),
  },
  clients: {
    findAll: () => get<ClientOnListDto[]>('clients'),
  },
  services: {
    findAll: () => get<ServiceOnListDto[]>('services'),
    findOne: (id: string) => get<ServiceOnListDto>(`services/${id}`),
  },
  extendedUserServices: {
    findCurrent: () =>
      get<ServiceOnListDto | null>('extended-user-services/current'),
  },
  entitiesFile: {
    import: (formData: FormData) =>
      post<ImportResultDto>('entities-file/import', formData, multipartConfig),
    export: () => get<Blob>('entities-file/export', { responseType: 'blob' }),
  },
  chat: {
    createMessage: (requestId: string, formData: FormData) =>
      post(`service-requests/${requestId}/chat`, formData, multipartConfig),
    listMessages: (requestId: string) =>
      get<MessageList>(`service-requests/${requestId}/chat`),
    downloadFile: (requestId: string, fileId: string) =>
      get<Blob>(`service-requests/${requestId}/chat/files/${fileId}`, {
        responseType: 'blob',
      }),
  },
};

export { loadApiAccessToken, setApiAccessToken, getApiAccessTokenIssuedAt };

export default api;
