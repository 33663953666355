import { PATH_APP } from './paths';
import { lazy } from 'react';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { Subjects } from '../hooks/useAuth';
import { redirectTo } from 'src/routes/index';
import { AbilityAction } from 'clima-service-api/dist/auth';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP.root,
      component: redirectTo(PATH_APP.deviceBase.root),
    },

    // ACCOUNT
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.account,
      component: lazy(() => import('src/views/auth/AccountView')),
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.users.root,
      canAccess: [AbilityAction.Manage, Subjects.User],
      component: lazy(() => import('src/views/management/UserListView')),
    },
    {
      exact: true,
      path: PATH_APP.management.users.newUser,
      canAccess: [AbilityAction.Manage, Subjects.User],
      component: lazy(() => import('src/views/management/UserFormView')),
    },
    {
      exact: true,
      path: PATH_APP.management.users.editUser,
      canAccess: [AbilityAction.Manage, Subjects.User],
      component: lazy(() => import('src/views/management/UserFormView')),
    },
    {
      exact: true,
      path: PATH_APP.management.entitiesFile,
      canAccess: [AbilityAction.Manage, Subjects.Client],
      component: lazy(() => import('src/views/management/EntitiesFileView')),
    },
    {
      exact: true,
      path: PATH_APP.management.root,
      component: redirectTo(PATH_APP.management.users.root),
    },

    // DEVICE BASE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.deviceBase.devices.root,
      canAccess: [
        [AbilityAction.Read, Subjects.Device],
        [AbilityAction.ReadOwn, Subjects.Device],
      ],
      component: lazy(() => import('src/views/devices/DeviceListView')),
    },
    {
      exact: true,
      path: PATH_APP.deviceBase.devices.new,
      canAccess: [AbilityAction.Manage, Subjects.Device],
      component: lazy(() => import('src/views/devices/DeviceFormView')),
    },
    {
      exact: true,
      path: PATH_APP.deviceBase.devices.edit,
      canAccess: [AbilityAction.Manage, Subjects.Device],
      component: lazy(() => import('src/views/devices/DeviceFormView')),
    },
    {
      exact: true,
      path: PATH_APP.deviceBase.serviceRequests.root,
      canAccess: [
        [AbilityAction.Read, Subjects.ServiceRequest],
        [AbilityAction.ReadOwn, Subjects.ServiceRequest],
      ],
      component: lazy(() => import('src/views/devices/ServiceRequestListView')),
    },
    {
      exact: true,
      path: PATH_APP.deviceBase.serviceRequests.new,
      canAccess: [AbilityAction.Create, Subjects.ServiceRequest],
      component: lazy(() => import('src/views/devices/ServiceRequestFormView')),
    },
    {
      exact: true,
      path: PATH_APP.deviceBase.serviceRequests.edit,
      canAccess: [AbilityAction.Manage, Subjects.ServiceRequest],
      component: lazy(() => import('src/views/devices/ServiceRequestFormView')),
    },
    {
      exact: true,
      path: PATH_APP.deviceBase.serviceRequests.chat,
      canAccess: [AbilityAction.Read, Subjects.Message],
      component: lazy(() => import('src/views/devices/ServiceRequestChatView')),
    },
    {
      exact: true,
      path: PATH_APP.deviceBase.root,
      component: redirectTo(PATH_APP.deviceBase.serviceRequests.root),
    },

    // ----------------------------------------------------------------------
    {
      component: redirectTo('/404'),
    },
  ],
};

export default AppRoutes;
