import { Icon } from '@iconify/react';
import React from 'react';
import sunFill from '@iconify-icons/eva/sun-fill';
import moonFill from '@iconify-icons/eva/moon-fill';
import { MIconButton } from 'src/theme';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

function DarkMode() {
  const { themeMode, toggleMode } = useSettings();
  const darkMode = themeMode === 'dark';

  const onToggleTheme = () => toggleMode();

  return (
    <MIconButton onClick={onToggleTheme} color={'default'}>
      <Icon icon={darkMode ? sunFill : moonFill} width={20} height={20} />
    </MIconButton>
  );
}

export default DarkMode;
