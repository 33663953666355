import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getInitialize, refreshIfNeeded } from 'src/redux/slices/auth';
import { useLocation } from 'react-router';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

JwtProvider.propTypes = {
  children: PropTypes.node,
};

function JwtProvider({ children }) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  let location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(refreshIfNeeded());
    }
  }, [dispatch, location, isAuthenticated]);

  useEffect(() => {
    dispatch(getInitialize());
  }, [dispatch]);

  return <>{children}</>;
}

export default JwtProvider;
