import React from 'react';
import PropTypes from 'prop-types';
import { MAvatar } from 'src/theme';
import createAvatar from 'src/utils/createAvatar';

// ----------------------------------------------------------------------

UserAvatar.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
};

function UserAvatar({ user, className }) {
  const avatar = createAvatar(user.name);
  return (
    <MAvatar
      src={user.photoURL}
      alt={user.name}
      color={user.photoURL ? 'default' : avatar.color}
      className={className}
    >
      {avatar.name}
    </MAvatar>
  );
}

export default UserAvatar;
