import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from 'src/redux/slices/auth';
import { AbilityAction } from 'clima-service-api/dist/auth';

// ----------------------------------------------------------------------

export const Subjects = Object.freeze({
  User: 'UserEntity',
  Device: 'DeviceEntity',
  Model: 'ModelEntity',
  Client: 'ClientEntity',
  Service: 'ServiceEntity',
  ServiceRequest: 'ServiceRequestEntity',
  Message: 'MessageEntity',
  all: 'all',
});

// ----------------------------------------------------------------------

export default function useAuth() {
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated, ability } = useSelector(
    (state) => state.auth,
  );

  return {
    user: user,
    isLoading: isLoading,
    isAuthenticated: isAuthenticated,

    login: ({ email, password }) =>
      dispatch(
        login({
          email: email,
          password: password,
        }),
      ),

    logout: () => dispatch(logout()),

    resetPassword: () => 'TODO',

    updateProfile: () => 'TODO',

    can: (action, subject) => ability.can(action, subject),

    canRead: (subject) => ability.can(AbilityAction.Read, subject),

    canManage: (subject) => ability.can(AbilityAction.Manage, subject),

    canCreate: (subject) => ability.can(AbilityAction.Create, subject),
  };
}
