import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import userReducer from './slices/user';
import settingsReducer from './slices/settings';
import notificationsReducer from './slices/notifications';
import authReducer from './slices/auth';

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  notifications: notificationsReducer,
  auth: authReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

export { store };
