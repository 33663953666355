// ----------------------------------------------------------------------

export default function Breadcrumbs() {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: 16,
          marginRight: 16,
        },
      },
    },
  };
}
