import React from 'react';
import { ThemeConfig } from './theme';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store } from './redux/store';
import routes, { renderRoutes } from 'src/routes';
import ScrollToTop from 'src/components/ScrollToTop';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import NotistackProvider from 'src/components/NotistackProvider';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

import JwtProvider from 'src/components/Auth/JwtProvider';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeConfig>
          <NotistackProvider>
            <Router history={history}>
              <JwtProvider>
                <ScrollToTop />
                {renderRoutes(routes)}
              </JwtProvider>
            </Router>
          </NotistackProvider>
        </ThemeConfig>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
