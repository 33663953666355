import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP } from 'src/routes/paths';
import { Subjects } from 'src/hooks/useAuth';
import { AbilityAction } from 'clima-service-api/dist/auth';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />,
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'users',
        icon: ICONS.user,
        canAccess: [AbilityAction.Manage, Subjects.User],
        href: PATH_APP.management.users.root,
        items: [
          {
            title: 'list',
            href: PATH_APP.management.users.root,
          },
          {
            title: 'newUser',
            href: PATH_APP.management.users.newUser,
          },
        ],
      },
      {
        title: 'entitiesFile',
        icon: ICONS.upload,
        canAccess: [AbilityAction.Manage, Subjects.Client],
        href: PATH_APP.management.entitiesFile,
      },
    ],
  },

  // DEVICE BASE
  // ----------------------------------------------------------------------
  {
    subheader: 'deviceBase',
    items: [
      {
        title: 'devices',
        icon: ICONS.elements,
        canAccess: [
          [AbilityAction.Read, Subjects.Device],
          [AbilityAction.ReadOwn, Subjects.Device],
        ],
        href: PATH_APP.deviceBase.devices.root,
        items: [
          {
            title: 'list',
            href: PATH_APP.deviceBase.devices.root,
          },
          {
            title: 'newDevice',
            canAccess: [AbilityAction.Manage, Subjects.Device],
            href: PATH_APP.deviceBase.devices.new,
          },
        ],
      },
      {
        title: 'serviceRequests',
        icon: ICONS.page,
        canAccess: [
          [AbilityAction.Read, Subjects.ServiceRequest],
          [AbilityAction.ReadOwn, Subjects.ServiceRequest],
        ],
        href: PATH_APP.deviceBase.serviceRequests.root,
        items: [
          {
            title: 'list',
            href: PATH_APP.deviceBase.serviceRequests.root,
          },
          {
            title: 'newServiceRequest',
            canAccess: [AbilityAction.Create, Subjects.ServiceRequest],
            href: PATH_APP.deviceBase.serviceRequests.new,
          },
        ],
      },
    ],
  },
];

export default navConfig;
