import { createSlice } from '@reduxjs/toolkit';
import api, {
  getApiAccessTokenIssuedAt,
  loadApiAccessToken,
  setApiAccessToken,
} from 'src/utils/api';
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  user: null,
  ability: new Ability(),
  notifications: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      const rules = (action.payload.user || {}).rules;
      if (rules != null) state.ability.update(unpackRules(rules));
    },
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      const rules = (action.payload.user || {}).rules;
      if (rules != null) state.ability.update(unpackRules(rules));
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.ability.update(unpackRules([]));
    },
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function login({ email, password }) {
  return async (dispatch) => {
    const response = await api.auth.login({ email, password });
    const { accessToken, user } = response.data;
    setApiAccessToken(accessToken);
    dispatch(slice.actions.loginSuccess({ user }));
  };
}

export function refreshIfNeeded() {
  return async (dispatch) => {
    const issuedAt = getApiAccessTokenIssuedAt();
    if (issuedAt == null) {
      dispatch(logout());
    }
    const minutesAgo = (Date.now() - new Date(issuedAt * 1000)) / (1000 * 60);
    if (minutesAgo > 10) {
      try {
        const response = await api.auth.refresh();
        const { accessToken, user } = response.data;
        setApiAccessToken(accessToken);
        dispatch(slice.actions.loginSuccess({ user }));
      } catch (error) {
        console.log(error.message);
        dispatch(logout());
      }
    }
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setApiAccessToken(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    const dispatchInitialize = (user) =>
      dispatch(
        slice.actions.getInitialize({ isAuthenticated: !!user, user: user }),
      );

    try {
      dispatch(slice.actions.startLoading());
      loadApiAccessToken();
      const response = await api.auth.getAccount();
      dispatchInitialize(response.data.user);
    } catch (error) {
      console.log(error.message);
      dispatchInitialize(null);
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    try {
      const response = await api.auth.getNotifications();
      dispatch(slice.actions.getNotificationsSuccess(response.data));
    } catch (error) {
      console.log(error.message);
      dispatch(slice.actions.getNotificationsSuccess(null));
    }
  };
}
