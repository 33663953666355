// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app',
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify'),
  },
  public: {
    newServiceRequest: '/service-request',
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
};

export const PATH_APP = {
  root: ROOTS.app,
  account: path(ROOTS.app, '/account'),
  management: {
    root: path(ROOTS.app, '/management'),
    users: {
      root: path(ROOTS.app, '/management/users'),
      newUser: path(ROOTS.app, '/management/users/new'),
      editUser: path(ROOTS.app, '/management/users/:id/edit'),
    },
    entitiesFile: path(ROOTS.app, '/management/entities-file'),
  },
  deviceBase: {
    root: path(ROOTS.app, '/base'),
    devices: {
      root: path(ROOTS.app, '/base/devices'),
      new: path(ROOTS.app, '/base/devices/new'),
      edit: path(ROOTS.app, '/base/devices/:id/edit'),
    },
    serviceRequests: {
      root: path(ROOTS.app, '/base/service-requests'),
      new: path(ROOTS.app, '/base/service-requests/new'),
      edit: path(ROOTS.app, '/base/service-requests/:id/edit'),
      chat: path(ROOTS.app, '/base/service-requests/:id/chat'),
    },
  },
};
