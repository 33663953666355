import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from 'react';
import PopoverMenu from 'src/components/PopoverMenu';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Box, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { MIconButton } from 'src/theme';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  btnLang: {
    padding: 0,
    width: 44,
    height: 44,
  },
  isSelected: {
    backgroundColor: theme.palette.background.selected,
  },
  icon: {
    boxShadow: `0 0 4px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

// ----------------------------------------------------------------------

function Languages() {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const languages = [
    {
      key: 'pl',
      icon: '/static/icons/ic_flag_pl.svg',
    },
    {
      key: 'en',
      icon: '/static/icons/ic_flag_en.svg',
    },
  ];

  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = languages.find(({ key }) => key === langStorage);

  const handleChangeLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        className={clsx(classes.btnLang, { [classes.isSelected]: isOpen })}
      >
        <img
          src={currentLang.icon}
          alt={currentLang.label}
          className={classes.icon}
        />
      </MIconButton>

      <PopoverMenu
        width={200}
        open={isOpen}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {languages.map(({ key, icon }) => (
            <MenuItem
              key={key}
              selected={key === langStorage}
              onClick={() => handleChangeLanguage(key)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box
                  component="img"
                  alt={t(`vars.languages.${key}`)}
                  src={icon}
                  className={classes.icon}
                />
              </ListItemIcon>

              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {t(`vars.languages.${key}`)}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Languages;
